const authClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const devMode = process.env.NODE_ENV === 'development';
const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
const apiPrefix = devMode ? 'dev.' : process.env.REACT_APP_API_PREFIX;

export {
    authClientId,
    devMode,
    basename,
    apiPrefix,
};
