import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    repoId: string;
    fontFamilyName: string;
    repoName: string;
    existingStyles?: string[];
    style?: string;
}

export function DetailsSection({ repoId, fontFamilyName, repoName, existingStyles, style }: Props) {
    return (
        <div className='font-details'>
            <div className='font-details__name'>
                <span className='value'>{fontFamilyName}</span>
            </div>
            {
                style && (
                    <div className='font-details__style'>
                        <span className='value'>{style}</span>
                    </div>
                )
            }
            <div className='font-details__repo'>
                <span className='value'>{repoName}</span>
            </div>
            {existingStyles && (
                <div className='font-details__style-count'>
                    <Link to={`/repository/${repoId}?familyName=${encodeURIComponent(fontFamilyName)}`} className='value'>{existingStyles.length} font styles</Link>
                </div>
            )}
        </div>
    );
}
