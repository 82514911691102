import { createSlice, PayloadAction } from 'redux-starter-kit';
import { FontProperty } from '../../../clients/fontClient';
import { FontProperties } from '../addFontPopup/AddFontPopup';


export const defaultProperties: FontProperty = {
    fontFamilyName: '',
    fontStyle: '',
    technology: 'Print',
    license: 'Open',
};

export interface AddFont {
    selectedProperties: FontProperty;
    savedProperties?: FontProperty;
    isLoading: boolean;
    showSnackbar: boolean;
    uploadSuccess: boolean;
    uploadFailed: boolean;
}

export interface Properties {
    properties: FontProperties[];
    file: File;
}

export const INITIAL_STATE: AddFont = {
    selectedProperties: defaultProperties,
    isLoading: false,
    showSnackbar: false,
    uploadSuccess: false,
    uploadFailed: false,
};

const slice = createSlice({
    slice: 'addFont',
    initialState: INITIAL_STATE,
    reducers: {
        onClosePopup: () => INITIAL_STATE,
        onAddFont: (state, { payload }: PayloadAction<Properties>) => { state.isLoading = true; },
        addFontSuccess: state => ({ ...INITIAL_STATE, uploadSuccess: true, isLoading: false }),
        addFontFailed: (state) => { state.isLoading = false; state.showSnackbar = true; state.uploadSuccess = false; state.uploadFailed = true; },
        hideSnackbar: (state) => { state.showSnackbar = false; state.uploadSuccess = false; state.uploadFailed = false; },
    },
});


export const { reducer } = slice;

export default slice;
