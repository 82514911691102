import React, { useState } from 'react';
import Pako from 'pako';
import VisibilitySensor from 'react-visibility-sensor';

import { shapes } from '@cimpress/react-components';
import { FONT_BASE_URL, Links } from '../../../clients/fontClient';
import { Status } from '../FontCard';

interface FontPreviewProps {
    content: string;
    size: number;
    fontStyle: string;
    fontFamily: string;
    color?: string;
    canDownloadFontFile: boolean;
    links: Links | undefined;
    repoId: string;
    status: Status;
}

interface FontRangeSize {
    minSize: number;
    maxSize: number;
    height: number;
}

interface FontPreviewStyle {
    height: string;
    fontFamily?: string;
    fontSize?: string;
    background?: string;
}

const { Spinner } = shapes;

// Data list, first item is fontSize and 2nd is height
const maxSizeToHeightLevelsArr = [
    [20, 30],
    [25, 50],
    [36, 80],
    [100, 100],
    [200, 120],
    [400, 240],
];

// Initializing size and height ranges
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fontSizeToHeightRange = ((maxSizeToHeightLevels) => {
    const SIZE = 0; const
        HEIGHT = 1;

    return maxSizeToHeightLevels.map((sizeHeight: number[], i: number) => {
        // from 2nd range onwards, minSize of the range is taken from
        // the maxSize of the previous range
        const prevRangeIndex = i - 1;
        return {
            minSize: i === 0 ? 0 : (maxSizeToHeightLevels[prevRangeIndex][SIZE]) + 1,
            maxSize: sizeHeight[SIZE],
            height: sizeHeight[HEIGHT],
        };
    });
})(maxSizeToHeightLevelsArr);

// This function will give the image height for a particular size
const getSizeRange = (size: number, rangeSizes: FontRangeSize[]) => {
    const range = rangeSizes.find((rangeSize, i) => size <= rangeSize.maxSize && size > rangeSize.minSize);
    return range ? range.height : undefined;
};

const getPreviewImageHeight = (size: number) => {
    const height = 150;
    return getSizeRange(size, fontSizeToHeightRange) || height;
};

const cimDocFontStyleMap = new Map([
    ['Normal', 'Normal'],
    ['Bold', 'Bold'],
    ['Italic', 'Italic'],
    ['BoldItalic', 'Bold, Italic'],
]);

function getTransientUdsDocument({ content, size, fontFamily, fontStyle, color, repoId, status }: FontPreviewProps) {
    const width = 100;
    const height = getPreviewImageHeight(size);
    const fontSize = `${size}pt`;

    const heightInMM = `${height}mm`;
    const widthInMM = `${width}mm`;
    const txtAreaWidthInMM = `${width - 8}mm`;

    const docContent = {
        version: 2,
        document: {
            surfaces: [
                {
                    name: 'front',
                    width: widthInMM,
                    height: heightInMM,
                    textAreas: [
                        {
                            position: {
                                x: '8mm',
                                y: '0mm',
                                width: txtAreaWidthInMM,
                                height: heightInMM,
                            },
                            horizontalAlignment: 'left',
                            verticalAlignment: 'top',
                            blockFlowDirection: 'horizontal-tb',
                            textFields: [
                                {
                                    fontSize,
                                    fontStyle: cimDocFontStyleMap.get(fontStyle),
                                    fontFamily,
                                    content,
                                    color: color || 'rgb(0,0,0)',
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        fontRepositoryUrl: `${FONT_BASE_URL}/${repoId}/draft`,
    };

    const deflatedContent = Pako.deflateRaw(JSON.stringify(docContent), { to: 'string' });
    const encodedContent = encodeURIComponent(btoa(deflatedContent));
    return `https://uds.documents.cimpress.io/v2/transient/deflate?document=${encodedContent}&type=preview`;
}

function getFontPreview(fontProps: FontPreviewProps) {
    const width = 340;
    return `http://rendering.documents.cimpress.io/v1/cse/preview?format=png&width=${width}&instructions_uri=${encodeURIComponent(getTransientUdsDocument(fontProps))}`;
}

const getRenderingAssetId = (links?: Links) => {
    if (links) {
        if (links.draft && links.draft['*']) {
            return links.draft['*'].renderingAssetId;
        }
        if (links.published && links.published['*']) {
            return links.published['*'].renderingAssetId;
        }
    }

    return '';
};

const createPreviewStyle = (props: FontPreviewProps, fontURL: string, isVisible: boolean) => {
    const style: FontPreviewStyle = {
        height: `${getPreviewImageHeight(props.size)}mm`,
    };

    if (Status.NoAsset !== props.status && props.canDownloadFontFile && isVisible) {
        style.fontFamily = `'${props.fontFamily}-${getRenderingAssetId(props.links)}'`;
        style.fontSize = `${props.size}pt`;
    } else {
        style.background = `url(${fontURL}) no-repeat center ${Status.NoAsset === props.status ? '/ 100px' : ''}`;
    }

    return style;
};

const renderPreview = (props: FontPreviewProps, fontURL: string, isVisible: boolean) => (
    <div className={isVisible && props.canDownloadFontFile ? 'inline-preview' : ''} style={createPreviewStyle(props, fontURL, isVisible)}>
        {Status.NoAsset !== props.status && props.canDownloadFontFile ? props.content : ''}
    </div>
);

const getFontFile = (repositoryId: string, links?: Links) => `${FONT_BASE_URL}/${repositoryId}/assets/${getRenderingAssetId(links)}`;

// Using data URL for default image: 1px transparent binary gif
const dummyDataImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const deleteIconURL = 'https://static.ux.cimpress.io/assets/icons/bin-1-l.svg';

export function PreviewSection(fontPreviewProps: FontPreviewProps) {
    const [imgURL, setImgURL] = useState(dummyDataImage);
    const fontImageURL = fontPreviewProps.canDownloadFontFile ? '' : getFontPreview(fontPreviewProps);
    const [isLoading, setIsLoading] = useState(!fontPreviewProps.canDownloadFontFile);

    return (
        <div className='preview-area'>
            {isLoading && <Spinner className='card-loader' />}
            <img className='dummy-preview' src={`${imgURL}`} alt='hidden' onLoad={() => setIsLoading(false)} />
            {fontPreviewProps.canDownloadFontFile
                && (
                    // eslint-disable-next-line react/no-danger
                    <style dangerouslySetInnerHTML={{ __html: `
                                    @font-face {
                                        font-family: '${fontPreviewProps.fontFamily}-${getRenderingAssetId(fontPreviewProps.links)}';
                                        src: url(${getFontFile(fontPreviewProps.repoId, fontPreviewProps.links)}) format('truetype');
                                    }
                                ` }}
                    />
                )}
            <VisibilitySensor partialVisibility={true}>
                {({ isVisible }) => {
                    if (isVisible) {
                        if (fontImageURL !== imgURL && !fontPreviewProps.canDownloadFontFile) {
                            setIsLoading(true);
                            setImgURL(fontImageURL);
                        }

                        if (Status.NoAsset === fontPreviewProps.status) {
                            setImgURL(deleteIconURL);
                        }
                    }
                    return renderPreview(fontPreviewProps, imgURL, isVisible);
                }}
            </VisibilitySensor>
        </div>
    );
}
