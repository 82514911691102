import { GroupedFont } from '../../../fonts/saga';
import { Font } from '../../../clients/fontClient';
import { SearchType } from '../Catalog';


interface FontListParams {
    familyName: string;
    groupedFonts: GroupedFont[];
    searchText: string;
    searchType: SearchType | undefined;
}

const getFont = (fonts: Font[], style?: string): Font => {
    if (style) {
        return fonts.filter(f => f.links && (f.links.draft || f.links.published)).find(f => f.style.toLocaleLowerCase() === style) || fonts[0];
    }
    return fonts.find(f => f.links && (f.links.draft || f.links.published)) || fonts[0];
};

const getFontStyles = (fonts: Font[]) => fonts.map(f => f.style);

export const getFontList = ({ familyName, groupedFonts, searchText, searchType }: FontListParams) => {
    if (familyName) {
        const groupedFont = groupedFonts.find(g => g.familyName === familyName);
        if (searchText) {
            // filtering font by style when searched in detailed view
            const filtered = groupedFont && groupedFont.fonts.filter(s => s.style.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
            return filtered || [];
        }
        return (groupedFont && groupedFont.fonts) || [];
    } if (searchType && searchText) {
        // filtering groupedFonts by style when searched in grouped view
        if (searchType === SearchType.fontStyle) {
            const fontsByStyle = groupedFonts
                .filter(gr => gr.fonts
                    .find(g => g.style.toLocaleLowerCase() === searchText.toLocaleLowerCase()))
                .map(g => ({ ...getFont(g.fonts, searchText), existingStyles: getFontStyles(g.fonts) }));

            return fontsByStyle;
        }
        // filtering groupedFonts by familyName when searched in grouped view
        const fontsByFamilyName = groupedFonts
            .filter(groupedFont => groupedFont.familyName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))
            .map(groupedFont => ({ ...getFont(groupedFont.fonts), existingStyles: getFontStyles(groupedFont.fonts) }));

        return fontsByFamilyName;
    }
    return groupedFonts.map(g => ({ ...getFont(g.fonts), existingStyles: getFontStyles(g.fonts) }));
};
