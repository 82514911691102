import { combineReducers } from 'redux-starter-kit';
import { StateType } from 'typesafe-actions';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { reducer as repository } from '../screens/repository/slice';
import { reducer as fontProperties } from '../screens/catalog/addFontPopup/slice';
import { reducer as tenantInfo } from '../components/header/slice';
import { reducer as font } from '../fonts/slice';

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    repository,
    fontProperties,
    font,
    tenantInfo,
});

export type RootState = StateType<ReturnType<typeof rootReducer>>;
export default rootReducer;
