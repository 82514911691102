/**
 * Parse out the query parameters from a given URL.
 * @param {string} property - key want to extract
 * @param {string} queryString - url
 */

export const getQueryVariable = (property: string, queryString: string) => {
    const params = new URLSearchParams(queryString);
    return params.get(property);
};
