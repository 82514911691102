import React, { useState, ReactNode } from 'react';

import './fileDropZone.scss';

interface Props {
    accept?: string[];
    helpText?: string;
    bsStyle?: string;
    children?: ReactNode;
    onFileUpload(payload: File[]): void;
    onFileInvalid?(): void;
}

const isInValidFileExtension = (file: File, validExtensions: string[]): boolean => {
    const extension = file && file.name && `.${file.name.split('.').pop()}`;
    return !extension || validExtensions.indexOf(extension) === -1;
};

const isAllFilesValid = (files: File[], validExtensions: string[] | undefined): boolean => {
    if (!validExtensions || (validExtensions && validExtensions.length === 0)) return true; // can accept all file extensions if validExtensions is undefined or empty
    return !files.some(file => isInValidFileExtension(file, validExtensions));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFileFromDataTransfer = (dataTransfer: any): File[] => {
    if (dataTransfer.items) {
        const dataTransferItems = dataTransfer.items;
        const files = Object.keys(dataTransferItems)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((item: any) => dataTransferItems[item].kind === 'file')
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((item: any) => dataTransferItems[item].getAsFile());
        return files;
    }
    return dataTransfer.files;
};

export function FileDropZone({ accept, helpText, bsStyle, children, onFileUpload, onFileInvalid }: Props) {
    const wrapperClassNames = ['file-dropzone-wrapper'];
    bsStyle && wrapperClassNames.push(`has-${bsStyle}`);

    const [isDragOver, setIsDragOver] = useState(false);

    const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = getFileFromDataTransfer(event.dataTransfer);
        if (isAllFilesValid(files, accept)) {
            onFileUpload(files);
        } else {
            onFileInvalid && onFileInvalid();
        }
        setIsDragOver(false);
    };

    const dragoverHandler = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.dataTransfer.dropEffect = 'copy';
    };
    const ondragenter = () => setIsDragOver(true);
    const ondragleave = () => setIsDragOver(false);

    return (
        <div className={wrapperClassNames.join(' ')}>
            <div
                className={`file-dropzone ${isDragOver ? 'file-drag-over' : ''}`}
                onDrop={dropHandler}
                onDragOver={dragoverHandler}
                onDragEnter={ondragenter}
                onDragLeave={ondragleave}
            >
                { children }
            </div>
            { helpText && <small className='help-block'>{helpText}</small> }
        </div>
    );
}
