import { createSlice, PayloadAction } from 'redux-starter-kit';

import { GroupedFont } from './saga';

export interface Catalog {
    groupedFonts: GroupedFont[];
    isLoading: boolean;
    loaded: boolean;
    loadSuccess: boolean;
    isPublishing: boolean;
    publishSuccess: boolean;
    showSnackbar: boolean;
    isDeleting: boolean;
    deleteSuccess: boolean;
}

export const INITIAL_STATE: Catalog = {
    groupedFonts: [],
    isLoading: false,
    loaded: false,
    loadSuccess: false,
    isPublishing: false,
    publishSuccess: false,
    showSnackbar: false,
    isDeleting: false,
    deleteSuccess: false,
};

const slice = createSlice({
    slice: 'font',
    initialState: INITIAL_STATE,
    reducers: {
        onLoadFonts: () => INITIAL_STATE,
        onQueryFonts: (state) => { state.isLoading = true; state.loaded = false; },
        fontsLoadSuccess: (state, { payload }: PayloadAction<GroupedFont[]>) => { state.loadSuccess = true; state.isLoading = false; state.groupedFonts = payload; state.loaded = true; },
        fontsLoadFailed: (state) => { state.isLoading = false; state.showSnackbar = true; state.loadSuccess = false; state.loaded = true; },
        hideSnackbar: (state) => { state.showSnackbar = false; state.loadSuccess = false; state.publishSuccess = false; },
        onPublishFont: (state, { payload }: PayloadAction<string>) => { state.isPublishing = true; },
        fontPublishSuccess: (state) => { state.publishSuccess = true; state.isPublishing = false; },
        fontPublishFailed: (state) => { state.publishSuccess = false; state.isPublishing = false; },
        onDeleteFont: (state, { payload }: PayloadAction<string>) => { state.isDeleting = true; },
        fontDeleteSuccess: (state) => { state.deleteSuccess = true; state.isDeleting = false; },
        fontDeleteFailed: (state) => { state.deleteSuccess = false; state.isDeleting = false; },
        resetDeleteSuccess: (state) => { state.deleteSuccess = false; },
    },
});

export const { reducer } = slice;

export default slice;
