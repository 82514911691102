import { parse as openTypeParse } from 'opentype.js';

export function getFontDetailsFromFile(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        reader.onload = (e: any) => {
            try {
                const font = openTypeParse(e.target.result);
                resolve({ fontFamily: font.names.fontFamily.en, fontSubFamily: font.names.fontSubfamily.en });
            } catch (err) {
                reject(err);
            }
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsArrayBuffer(file);
    });
}
