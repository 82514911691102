import React, { useState } from 'react';
import { Button } from '@cimpress/react-components';

import './addFont.scss';
import AddFontPopup from '../addFontPopup/AddFontPopup';

export default function AddFont() {
    const [showAddFontModal, setShowAddFontModal] = useState(false);

    const onAddFontClick = () => setShowAddFontModal(true);
    const onCancel = () => setShowAddFontModal(false);

    return (
        <>
            <Button className='add-font' onClick={onAddFontClick}>Add Font</Button>
            <AddFontPopup
                isOpen={showAddFontModal}
                closePopup={onCancel}
            />
        </>
    );
}
