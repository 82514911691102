import React, { ReactNode } from 'react';
import { Button } from '@cimpress/react-components';

import './fileUploadButton.scss';

interface Props {
    children?: ReactNode;
    /**
   * The type of button you are creating.
   */
    type?:
    | 'default'
    | 'primary'
    | 'dark'
    | 'secondary'
    | 'link'
    | 'outline-dark'
    | 'outline-secondary';

    /**
   * The callback function used when a selected file changes.
   */
    onFileUpload: (files: File[]) => void;

    accept?: string[];
}


export function FileUploadButton({ children, type, accept, onFileUpload }: Props) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (event: any) => onFileUpload(event.target.files);
    return (
        <div className='upload-btn-wrapper'>
            <Button type={type}><input type='file' onChange={onChange} accept={accept && accept.join(',')} />{ children }</Button>
        </div>
    );
}
