import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, TextField, Button, Select, shapes, Snackbar, Checkbox } from '@cimpress/react-components';

import './addFontPopup.scss';
import { RootState } from '../../../state/rootReducer';
import slice, { Properties } from './slice';
import { FontProperty, Font } from '../../../clients/fontClient';
import FontUpload from '../../../components/fileUpload';
import { getFontDetailsFromFile } from '../../../fontUtil';
import { GroupedFont } from '../../../fonts/saga';

interface Props {
    font?: Font;
    isOpen: boolean;
    selectedFontProperty: FontProperty;
    uploadSuccess: boolean;
    uploadFailed: boolean;
    isLoading: boolean;
    showSnackbar: boolean;
    groupedFonts: GroupedFont[];
    closePopup(): void;
    onClosePopup(): void;
    addFont({ properties, file }: Properties): void;
    hideSnackbar(): void;
}

export interface FontProperties {
    type: string;
    value: string;
    options?: string[];
    error?: string;
    isRequired: boolean;
}

const validFontFiles = ['.ttf', '.otf'];
export const validFontStyles = ['Normal', 'Bold', 'Italic', 'BoldItalic'];

const fontProperties: FontProperties[] = [
    {
        type: 'familyName',
        value: '',
        error: '',
        isRequired: true,
    },
    {
        type: 'style',
        options: validFontStyles,
        value: '',
        error: '',
        isRequired: true,
    },
    {
        type: 'technology',
        options: ['Print', 'Embroidery'],
        value: 'Print',
        isRequired: false,
    },
    {
        type: 'license',
        options: ['Open', 'Restricted'],
        value: 'Open',
        isRequired: false,
    },
];

const { Spinner } = shapes;

export interface Alert {
    isShown: boolean;
    message: string;
    style: 'danger' | 'info' | 'success' | 'warning' | undefined;
    autoHide: boolean;
}

export const defaultAlert: Alert = {
    isShown: false,
    message: '',
    style: undefined,
    autoHide: false,
};

const fontStyleMap = new Map([
    ['regular', 'Normal'],
    ['normal', 'Normal'],
    ['bold', 'Bold'],
    ['italic', 'Italic'],
    ['oblique', 'Italic'],
    ['bold italic', 'BoldItalic'],
    ['bold oblique', 'BoldItalic'],
]);

export function AddFontPopup({ font, isOpen, closePopup, onClosePopup, addFont, uploadSuccess, isLoading, hideSnackbar, uploadFailed, groupedFonts }: Props) {
    const [defaultProperties, setDefaultProperties] = useState();
    const [properties, setProperties] = useState(fontProperties);
    const [fileUploadErrorMessage, setFileUploadErrorMessage] = useState('');
    const [file, setFile] = useState();
    const [alert, setAlert] = useState(defaultAlert);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [duplicateFileWarning, setDuplicateFileWarning] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);

    const getFontProperties = (fontToUpdate: Font) => fontProperties.map((p) => {
        if (p.type === 'familyName') {
            return { ...p, value: fontToUpdate.familyName };
        }
        if (p.type === 'style') {
            return { ...p, value: fontToUpdate.style };
        }
        if (p.type === 'technology') {
            return { ...p, value: fontToUpdate.tech };
        }
        if (p.type === 'licence') {
            return { ...p, value: fontToUpdate.license };
        }
        return p;
    });

    const validateForm = () => {
        const mandatoryFieldsEmpty = properties.some(property => property.isRequired && property.value === '');
        if (mandatoryFieldsEmpty) {
            setProperties(properties.map(property => (property.isRequired && property.value === '' ? { ...property, error: 'This field is mandatory' } : { ...property, error: '' })));
        }
        if (!file) {
            setFileUploadErrorMessage('Please upload a font file');
        }
        if (mandatoryFieldsEmpty || !file) {
            return;
        }
        addFont({ properties, file });
    };


    const onSave = () => validateForm();

    function setProperty(type: string, value: string, error: string) {
        setProperties(properties.map(property => (property.type === type ? { ...property, value, error } : property)));
    }

    function warningIfDuplicateFile(fontFamily: string, style: string, publishedFont: Font | undefined) {
        const fontGroup = groupedFonts.find(g => g.familyName === fontFamily);
        if (!publishedFont && fontGroup && fontGroup.fonts.some(f => f.style === style)) {
            setDuplicateFileWarning('There is already a font with same font family and style added to this repository');
        } else if (publishedFont && publishedFont.familyName !== '' && publishedFont.familyName !== fontFamily) {
            setDuplicateFileWarning(`You are trying to upload ${fontFamily} within ${publishedFont.familyName} font family. Please upload the correct file`);
        }
    }

    async function preFillFontDetailsFromFile(fontFamily: string, style: string) {
        setProperties(properties.map((property) => {
            if (property.type === 'familyName' && fontFamily) {
                return { ...property, value: fontFamily };
            }
            if (property.type === 'style' && style) {
                return { ...property, value: style };
            }
            return property;
        }));
    }

    const onUpload = async (fileUploaded: File | undefined) => {
        setFile(fileUploaded);
        setFileUploadErrorMessage('');
        if (fileUploaded) {
            const { fontFamily, fontSubFamily } = await getFontDetailsFromFile(fileUploaded) as {fontFamily: string; fontSubFamily: string};
            const style = fontStyleMap.get(fontSubFamily.toLocaleLowerCase()) || '';
            !font && preFillFontDetailsFromFile(fontFamily, style);
            warningIfDuplicateFile(fontFamily, style, font);
        } else {
            setDuplicateFileWarning('');
            setProperties(defaultProperties || fontProperties);
        }
    };

    const onFileInvalid = () => {
        setFile(null);
        setFileUploadErrorMessage('Please enter .otf/.ttf file formats');
    };

    const onHideSnackbar = () => {
        setAlert(defaultAlert);
        hideSnackbar();
    };

    const onCancel = () => {
        closePopup();
        onClosePopup();
        setProperties(defaultProperties || fontProperties);
        setFileUploadErrorMessage('');
        setFile(null);
        setAlert(defaultAlert);
        setIsTermsAccepted(false);
        setDuplicateFileWarning('');
    };

    useEffect(() => {
        if (font) {
            const updatedFontProperties = getFontProperties(font);
            setProperties(updatedFontProperties);
            setDefaultProperties(updatedFontProperties);
            setIsDisabled(true);
        }
    }, [font]);

    useEffect(() => {
        if (uploadSuccess) {
            closePopup();
            setProperties(defaultProperties || fontProperties);
            onClosePopup();
            setAlert({ isShown: true, message: 'Font has been saved.', style: 'success', autoHide: true });
            setIsTermsAccepted(false);
        }
        if (uploadFailed) {
            setAlert({ isShown: true, message: 'Font Couldn\'t be saved, please try again.', style: 'danger', autoHide: false });
        }
    }, [uploadSuccess, onClosePopup, closePopup, uploadFailed, defaultProperties]);

    const onChangeFontFamilyName = (e: ChangeEvent<HTMLInputElement>, key: string) => {
        setProperty(key, e.currentTarget.value, '');
    };
    const onChangeSelectOption = (e: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        setProperty(e.property, e.value, '');
    };

    const createSelectOptions = (options: string[] | undefined, property: string) => options && options.map(option => ({
        value: option,
        label: option,
        property,
    }));

    const toggleCheckbox = () => setIsTermsAccepted(!isTermsAccepted);

    const getBsStyle = () => {
        if (fileUploadErrorMessage !== '') {
            return 'error';
        }
        if (duplicateFileWarning !== '') {
            return 'warning';
        }
        return '';
    };

    return (
        <>
            <Snackbar show={alert.isShown} bsStyle={alert.style} onHideSnackbar={onHideSnackbar} delay={alert.autoHide ? 4000 : undefined}>
                {alert.message}
            </Snackbar>
            <Modal
                className='add-font-form'
                title='Add font'
                show={isOpen}
                footer={(
                    <>
                        <Button onClick={onSave} type='primary' disabled={!isTermsAccepted}>Save</Button>
                        <Button onClick={onCancel}>Cancel</Button>
                    </>
                )}
            >
                {isLoading && <Spinner className='modal-spinner' />}

                {isOpen && (
                    <FontUpload
                        accept={validFontFiles}
                        helpText={fileUploadErrorMessage || duplicateFileWarning}
                        bsStyle={getBsStyle()}
                        onUpload={onUpload}
                        onFileInvalid={onFileInvalid}
                    />
                )}

                <TextField
                    label='Font Family Name'
                    value={properties[0].value}
                    helpText={properties[0].error ? properties[0].error : ''}
                    bsStyle={properties[0].error ? 'error' : ''}
                    onChange={event => onChangeFontFamilyName(event, 'familyName')}
                    required={true}
                    disabled={isDisabled}
                />

                <Select
                    value={properties[1].value}
                    onChange={onChangeSelectOption}
                    tether={true}
                    label='Select a style'
                    containerClassName={properties[1].error ? 'has-error' : ''}
                    helpText={properties[1].error}
                    options={createSelectOptions(properties[1].options, 'style')}
                    clearable={false}
                    required={true}
                    disabled={isDisabled}
                />

                <Select
                    value={properties[2].value}
                    onChange={onChangeSelectOption}
                    options={createSelectOptions(properties[2].options, 'technology')}
                    clearable={false}
                    label='Technology'
                    disabled={isDisabled}
                />

                <Select
                    value={properties[3].value}
                    onChange={onChangeSelectOption}
                    options={createSelectOptions(properties[3].options, 'license')}
                    clearable={false}
                    label='License'
                    disabled={isDisabled}
                />
                <div className='terms-condition'>
                    <div className='terms-text'>
                        You grant to Cimpress Schweiz GmbH a license to copy,
                        use and display fonts furnished by you to Cimpress Schweiz GmbH through this upload page for use in providing your portal and its products.
                        The license shall permit Cimpress Schweiz GmbH to store the uploaded font(s) on its systems and servers for use in providing this portal and its products.
                        You agree to grant Cimpress Schweiz GmbH sufficient license rights to support the maximum number of concurrent users and or
                        servers to properly manage the related volume of your use of the portal and ordering of products by you and your Customers.
                        The license shall permit Cimpress Schweiz GmbH to copy the uploaded font for the purpose of fulfillment, including printing.
                    </div>
                    <Checkbox
                        className='terms-checkbox'
                        label={<b>I Accept</b>}
                        checked={isTermsAccepted}
                        onChange={toggleCheckbox}
                    />
                </div>
            </Modal>
        </>
    );
}

export default connect(
    (state: RootState) => ({
        selectedFontProperty: state.fontProperties.selectedProperties,
        uploadSuccess: state.fontProperties.uploadSuccess,
        uploadFailed: state.fontProperties.uploadFailed,
        isLoading: state.fontProperties.isLoading,
        showSnackbar: state.fontProperties.showSnackbar,
        groupedFonts: state.font.groupedFonts,
    }),
    {
        addFont: slice.actions.onAddFont,
        onClosePopup: slice.actions.onClosePopup,
        hideSnackbar: slice.actions.hideSnackbar,
    },
)(AddFontPopup);
