import { devMode } from './settings';

export function logInfo(message: string) {
    if (devMode) {
        console.log(message); // eslint-disable-line no-console
    }
}

export function logError(message: string) {
    if (devMode) {
        console.error(message); // eslint-disable-line no-console
    }
}
