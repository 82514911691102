import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, TextField, Icon, colors, Tooltip, Modal, Snackbar } from '@cimpress/react-components';
import { connect } from 'react-redux';
import copyToClipboard from 'clipboard-copy';
import slice, { RepoPayload } from './slice';
import { RootState } from '../../state/rootReducer';
import { Alert } from '../catalog/addFontPopup/AddFontPopup';
import { INHERIT_REPOSITORY_ID } from './saga';
import { FontRepo } from '../../clients/fontClient';

const { ocean } = colors;

export const defaultAlert: Alert = {
    isShown: false,
    message: '',
    style: undefined,
    autoHide: false,
};

interface Props {
    currentFontRepo: FontRepo;
    showSnackbar: boolean;
    inheritSuccess: boolean | undefined;
    onSaveRepoName (payload: RepoPayload): void;
    onInheritRepo(): void;
    hideSnackbar(): void;
}

export function RepositoryActions({ currentFontRepo, showSnackbar, inheritSuccess, onSaveRepoName, onInheritRepo, hideSnackbar }: Props) {
    const [isEditing, setIsEditing] = useState(false);
    const [repoName, setRepoName] = useState();
    const [showInheritModal, setShowInheritModal] = useState(false);
    const [alert, setAlert] = useState(defaultAlert);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        setRepoName(currentFontRepo.name);
    }, [currentFontRepo]);

    useEffect(() => {
        if (showSnackbar) {
            if (inheritSuccess) {
                setAlert({ isShown: true, message: 'Fonts successfully copied from master repository.', style: 'success', autoHide: true });
            } else {
                setAlert({ isShown: true, message: 'Copying fonts from master repository failed. Please try again.', style: 'danger', autoHide: false });
            }
        }
    }, [inheritSuccess, showSnackbar]);

    const onChangeRepoName = (e: ChangeEvent<HTMLInputElement>) => {
        setErrorText('');
        setRepoName(e.currentTarget.value);
    };

    const onClickEdit = () => setIsEditing(true);
    const onClickSave = () => {
        if (!repoName) {
            setErrorText('This is a required field.');
            return;
        }
        setIsEditing(false);
        onSaveRepoName({ name: repoName, id: currentFontRepo.id });
    };
    const onClickCancel = () => {
        setRepoName(currentFontRepo.name);
        setErrorText('');
        setIsEditing(false);
    };

    const onClickCopy = () => {
        copyToClipboard(currentFontRepo.id);
        setAlert({ isShown: true, message: `Repository id copied to clipboard : ${currentFontRepo.id}`, style: 'success', autoHide: true });
    };

    const onAcceptInherit = () => {
        onInheritRepo();
        setShowInheritModal(false);
    };
    const onBtnClickInherit = () => setShowInheritModal(true);
    const onInheritModalClose = () => setShowInheritModal(false);
    const onHideSnackbar = () => {
        setAlert(defaultAlert);
        hideSnackbar();
    };
    const isInheritedFromMaster = () => !!(currentFontRepo.inheritedRepositories && currentFontRepo.inheritedRepositories.includes(INHERIT_REPOSITORY_ID));
    const inheritModalFooter = (
        <div>
            <Button type='primary' onClick={onAcceptInherit}>Yes</Button>
            <Button onClick={onInheritModalClose}>No</Button>
        </div>
    );

    return (
        <div className='edit-repository'>
            <Snackbar show={alert.isShown} bsStyle={alert.style} onHideSnackbar={onHideSnackbar} delay={alert.autoHide ? 4000 : undefined}>
                {alert.message}
            </Snackbar>
            {!isEditing && (
                <Tooltip className='edit-repo' direction='top' contents={repoName}>
                    <div className='edit-repo__name lead'>
                        {repoName}
                    </div>
                </Tooltip>
            )
            }
            {isEditing && (
                <TextField
                    className='edit-repo__text-box'
                    label='Font Repository Name'
                    value={repoName}
                    onChange={onChangeRepoName}
                    helpText={errorText}
                    bsStyle={errorText ? 'error' : ''}
                />
            )
            }
            {!isEditing && (
                <Tooltip direction='top' contents='Edit Repository Name'>
                    <Button className='edit-repo__edit-name' type='link' onClick={onClickEdit}>
                        <Icon
                            name='pencil-2-f'
                            size='lg'
                            color={ocean.darker}
                            style={{
                                marginLeft: '10px',
                                cursor: 'pointer',
                            }}
                        />
                    </Button>
                </Tooltip>
            )}
            {isEditing && (
                <>
                    <Tooltip direction='top' contents='Save'>
                        <Button className='edit-repo__save-edit' type='link' onClick={onClickSave}>
                            <Icon
                                name='check-1-l'
                                size='lg'
                                color={ocean.darker}
                                style={{
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                }}
                            />
                        </Button>
                    </Tooltip>
                    <Tooltip direction='top' contents='Cancel'>
                        <Button className='edit-repo__cancel-edit' type='link' onClick={onClickCancel}>
                            <Icon
                                name='close-l'
                                size='lg'
                                color={ocean.darker}
                                style={{
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                }}
                            />
                        </Button>
                    </Tooltip>
                </>
            )}
            <Tooltip direction='top' contents='Click here to get the Font Repository URL'>
                <Button className='url-copy-icon' type='link' onClick={onClickCopy}>
                    <Icon
                        name='copy-3-l'
                        size='lg'
                        color={ocean.darker}
                        style={{
                            marginLeft: '10px',
                            cursor: 'pointer',
                        }}
                    />
                </Button>
            </Tooltip>
            {!isInheritedFromMaster() && (
                <Tooltip direction='top' contents='Click here to inherit fonts from master repository'>
                    <Button className='font-inherit-icon' onClick={onBtnClickInherit} type='link'>
                        <Icon
                            name='compare-l'
                            size='lg'
                            color={ocean.darker}
                            style={{
                                marginLeft: '10px',
                                cursor: 'pointer',
                                pointerEvents: 'auto',
                            }}
                        />
                    </Button>
                </Tooltip>
            )}
            {showInheritModal && (
                <Modal
                    show={showInheritModal}
                    title='Copy fonts from master repository'
                    onRequestHide={onInheritModalClose}
                    footer={inheritModalFooter}
                    className='duplicate-font-modal'
                >
                If there are fonts in this repository that also exist in the Master repository, your fonts would be overridden by fonts from Master repository, would you like to continue?
                </Modal>
            )}
        </div>
    );
}

export default connect(
    (state: RootState) => ({
        showSnackbar: state.repository.showSnackbar,
        inheritSuccess: state.repository.inheritSuccess,
    }),
    {
        onSaveRepoName: slice.actions.onSaveRepoName,
        onInheritRepo: slice.actions.onInheritRepo,
        hideSnackbar: slice.actions.hideSnackbar,
    },
)(RepositoryActions);
