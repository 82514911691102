import axios, { AxiosError } from 'axios';

export interface AssetPermissions {
    canCreate: boolean;
    canRead: boolean;
}

export interface SettingPermissions {
    canRead: boolean;
    canManage: boolean;
}

export interface FontsPermission {
    canManage: boolean;
}

export interface Tenant {
    tenantId: string;
    tenantType: string;
    tenantDisplayName: string;
    permissions: {
        asset: AssetPermissions;
        fonts: FontsPermission;
        setting: SettingPermissions;
    };
}

interface ResourceReference {
    resourceType: string;
    resource: Resource;
}

interface Resource {
    identifier: string;
    permissions: string[];
}

interface PrincipalPermissionsResponse {
    [resourceType: string]: Resource[];
}

const READ_ASSET_PERMISSION = 'read:scenes';
const CREATE_ASSET_PERMISSION = 'create:scenes';
const READ_SETTING_PERMISSION = 'read:setting';
const MANAGE_SETTING_PERMISSION = 'manage:setting';
const MANAGE_FONT_PERMISSION = 'manage:fonts';

const PERMISSIONS = [
    READ_ASSET_PERMISSION,
    CREATE_ASSET_PERMISSION,
    READ_SETTING_PERMISSION,
    MANAGE_SETTING_PERMISSION,
];

const TENANT_CACHE_KEY = 'FONT_MANAGER_TENANT';

async function getPrincipalPermissions(sub: string, accessToken: string) {
    const urlEncodedSub = encodeURIComponent(sub);

    return axios.get<PrincipalPermissionsResponse>(`https://api.cimpress.io/auth/access-management/v1/principals/${urlEncodedSub}/permissions`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
        .then(({ data }) => data)
        .catch((error: AxiosError) => {
            throw error;
        });
}

const buildAvailableTenant = (resourceReference: ResourceReference) => {
    const { resource, resourceType } = resourceReference;
    const tenantId = resource.identifier;
    const tenantType = resourceType;
    const canReadAsset = resource.permissions.includes(READ_ASSET_PERMISSION);
    const canCreateAsset = resource.permissions.includes(CREATE_ASSET_PERMISSION);
    const canReadSetting = resource.permissions.includes(READ_SETTING_PERMISSION);
    const canManageSetting = resource.permissions.includes(MANAGE_SETTING_PERMISSION);
    const canManageFonts = resource.permissions.includes(MANAGE_FONT_PERMISSION);

    return {
        tenantId,
        tenantType,
        permissions: {
            asset: {
                canRead: canReadAsset,
                canCreate: canCreateAsset,
            },
            fonts: {
                canManage: canManageFonts,
            },
            setting: {
                canRead: canReadSetting,
                canManage: canManageSetting,
            },
        },
        tenantDisplayName: tenantId,
    };
};

const reduceNestedResources = (resourceReferences: ResourceReference[], [resourceType, resources]: [string, Resource[]]) =>
    [...resources.map(resource => ({ resourceType, resource })), ...resourceReferences]; // eslint-disable-line implicit-arrow-linebreak

const containsPermissions = (resourceReference: ResourceReference) => resourceReference.resource.permissions.some(permission => PERMISSIONS.includes(permission));

const buildAvailableTenants = (principalPermissions: PrincipalPermissionsResponse) => Object.entries(principalPermissions)
    .reduce(reduceNestedResources, [])
    .filter(containsPermissions)
    .map(buildAvailableTenant);

/**
 * Get users available tenants
 */
async function getAvailableTenants(sub: string, accessToken: string): Promise<Tenant[]> {
    let availableTenants: Tenant[] = [];
    try {
        const principalPermissions = await getPrincipalPermissions(sub, accessToken);
        availableTenants = buildAvailableTenants(principalPermissions);
    } catch (error) {
        throw error;
    }

    return availableTenants;
}

function setLastTenant(tenant?: Tenant) {
    if (tenant) {
        localStorage.setItem(TENANT_CACHE_KEY, JSON.stringify(tenant));
    } else {
        localStorage.removeItem(TENANT_CACHE_KEY);
    }
}

function getLastTenant() {
    const lastTenant = localStorage.getItem(TENANT_CACHE_KEY);
    if (lastTenant) {
        try {
            return JSON.parse(lastTenant) as Tenant;
        } catch {
            setLastTenant(); // clear last known tenant
        }
    }
    return null;
}

export {
    getAvailableTenants,
    getLastTenant,
    setLastTenant,
};
