import { takeEvery, all, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';
import { push } from 'connected-react-router';
import { saveRepo, updateRepo, fetchRepo, fetchAllRepositories, inheritRepos } from '../../clients/fontClient';
import slice, { getCurrentRepoId, RepoPayload } from './slice';
import { getCurrentTenant } from '../../components/header/slice';
import { logError } from '../../loggingManager';
import { Tenant } from '../../components/tenant';
import { apiPrefix } from '../../settings';

export const DEV_TEST_REPO_ID = 'cdb1fdd8-7eff-4501-8faa-71e20237b2ea';
export const MASTER_REPO_ID = 'aff15d65-e10f-492d-b8ea-cfd454c93c3f';

export const INHERIT_REPOSITORY_ID = apiPrefix ? DEV_TEST_REPO_ID : MASTER_REPO_ID;

export function* onSaveRepo(action: PayloadAction<RepoPayload>) {
    const repoName = action.payload.name;
    const repoId = action.payload.id;
    const tenant = (yield select(getCurrentTenant)) as Tenant;
    try {
        if (repoId) {
            const currentRepo = (yield call(updateRepo, repoName, tenant, repoId));
            yield put(slice.actions.saveSuccess(currentRepo));
        } else {
            const currentRepo = (yield call(saveRepo, { name: repoName, inheritedRepositories: action.payload.inheritedRepositories, tenant: { id: tenant.tenantId, type: tenant.tenantType } }));
            yield put(slice.actions.saveSuccess(currentRepo));
            yield put(push(`/repository/${currentRepo.id}`));
        }
    } catch {
        logError('Failed to create repository!');
        yield put(slice.actions.saveFailed());
    }
}

export function* onInheritRepo() {
    const repoId = (yield select(getCurrentRepoId)) as string;
    const tenant = (yield select(getCurrentTenant)) as Tenant;
    const inheritedRepositories = [INHERIT_REPOSITORY_ID];

    try {
        const currentRepo = yield call(inheritRepos, tenant, repoId, inheritedRepositories);
        yield put(slice.actions.inheritSuccess(currentRepo));
    } catch {
        logError('Failed to inherit master repository!');
        yield put(slice.actions.inheritFailed());
    }
}

export function* onLoadRepo(action: PayloadAction<string>) {
    try {
        const currentRepo = (yield call(fetchRepo, action.payload));
        yield put(slice.actions.loadSuccess(currentRepo));
    } catch {
        logError('Failed to load repository!');
        yield put(slice.actions.loadFailed());
    }
}

export function* onFetchRepoList() {
    const { tenantId, tenantType } = (yield select(getCurrentTenant)) as Tenant;
    try {
        const repoList = (yield call(fetchAllRepositories, { tenantId, tenantType }));
        yield put(slice.actions.fetchSuccess(repoList));
    } catch {
        logError('Failed to fetch repositories!');
        yield put(slice.actions.fetchFailed());
    }
}

export default function* repositorySaga() {
    return yield all([
        yield takeEvery(slice.actions.onSaveRepoName, onSaveRepo),
        yield takeEvery(slice.actions.onInheritRepo, onInheritRepo),
        yield takeEvery(slice.actions.startLoadingRepo, onLoadRepo),
        yield takeEvery(slice.actions.startFetchingRepoList, onFetchRepoList),
    ]);
}
