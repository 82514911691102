import React from 'react';
import CustomTextDropdown from '../../../components/customTextDropdown/CustomTextDropdown';

interface Props {
    onTextChange(text: string): void;
}

const CUSTOM_TEXT = 'Enter your own text…';
const textOptions = [
    CUSTOM_TEXT,
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0123456789 ¿ ? ¡ ! & @ ‘ ’ “ ” « » % * ^ # $ £ € ¢ / ( ) [ ] { } . , ® ©',
    'The quick brown fox jumps over the lazy dog',
];

export const DEFAULT_FONT_PREVIEW_TEXT = textOptions[3];

export default function FontText({ onTextChange }: Props) {
    return (
        <CustomTextDropdown
            label='Sample Text'
            placeholder={CUSTOM_TEXT}
            textOptions={textOptions}
            defaultOption={DEFAULT_FONT_PREVIEW_TEXT}
            onTextChange={onTextChange}
        />
    );
}
