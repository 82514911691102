import React from 'react';
import { Label } from '@cimpress/react-components';
import { Status } from '../FontCard';

interface Props {
    status: Status;
}

export function FooterSection({ status }: Props) {
    return (
        <div className='font-footer'>
            {
                status === Status.Published
                && <Label text={status} type='success' />
            }
            {
                status === Status.Draft
                && <Label text={status} type='default' />
            }
            {
                status === Status.NoAsset
                && <Label text='Deleted' type='warning' />
            }
        </div>
    );
}
