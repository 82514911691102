import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { CssLoader } from '@cimpress/react-components';
import { AuthProvider } from './components/AuthContext';
import Header from './components/header/Header';
import Catalog from './screens/catalog/Catalog';
import { history } from './state/createStore';

export default function App() {
    return (
        <ConnectedRouter history={history}>
            <AuthProvider>
                <CssLoader>
                    <div className='App'>
                        <Header />
                        <Switch>
                            <Route path='/' component={Catalog} exact={true} />
                            <Route path='/repository/:id' component={Catalog} />
                        </Switch>
                    </div>
                </CssLoader>
            </AuthProvider>
        </ConnectedRouter>
    );
}
