import React, { useState, ChangeEvent } from 'react';
import { TextField, Dropdown, Button } from '@cimpress/react-components';
import './customTextDropdown.scss';

interface Props {
    onTextChange(text: string): void;
    label: string;
    defaultOption: string;
    textOptions: string[];
    placeholder: string;
}

export default function CustomTextDropdown({ label, textOptions, defaultOption, placeholder, onTextChange }: Props) {
    const [customText, setCustomText] = useState(defaultOption);

    const changeText = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value;
        setCustomText(text);
        onTextChange(text);
    };

    const textSelected = (button: { target: { innerText: string } }) => {
        const text = placeholder === button.target.innerText ? '' : button.target.innerText;

        setCustomText(text);
        onTextChange(text);
    };

    return (
        <div className='textcombo-selector'>
            <TextField
                className='text-box'
                label={customText === '' ? '' : label}
                placeholder={placeholder}
                value={customText}
                onChange={changeText}
                rightAddon={(
                    <Dropdown>
                        {textOptions.map(opt => (<Button type='link' onClick={textSelected} key={opt}>{opt}</Button>))}
                    </Dropdown>
                )}
            />
        </div>
    );
}
