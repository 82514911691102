import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Snackbar, Modal, Button } from '@cimpress/react-components';
import { RootState } from '../../../state/rootReducer';
import slice from '../../../fonts/slice';
import AddFontPopup, { defaultAlert } from '../addFontPopup/AddFontPopup';
import FontCard from '../../../components/fontCard';
import { Font, FontRepo } from '../../../clients/fontClient';
import './fontList.scss';
import { Action } from '../../../components/fontCard/section/Actions';

interface Props {
    size: number;
    textContent: string;
    familyName: string;
    searchText: string;
    fonts: Font[];
    showSnackbar: boolean;
    showDeletedFonts: boolean;
    isPublishing: boolean;
    repoList: FontRepo[];
    publishSuccess: boolean;
    isDeleteSuccess: boolean;
    currentFontRepo: FontRepo;
    inheritSuccess: boolean;
    fontsLoaded: boolean;
    onQueryFonts(): void;
    onPublishFont(familyName: string): void;
    onDeleteFont(fontId: string): void;
    onLoadFonts(): void;
    hideSnackbar(): void;
    resetDeleteSuccess(): void;
}

enum FontTech {
    Print ='Print',
    Embroidery = 'Embroidery',
}

export enum Status {
    Draft= 'Draft',
    Published= 'Published',
    NoAsset= 'No Assets',
}

const getStatus = (font: Font) => {
    if (font.links && font.links.draft) {
        return Status.Draft;
    } if (font.links && font.links.published) {
        return Status.Published;
    }
    return Status.NoAsset;
};

const techListOrdered = [FontTech.Print.valueOf(), FontTech.Embroidery.valueOf()];

function FontList({ size,
    textContent,
    showSnackbar,
    showDeletedFonts,
    familyName,
    searchText,
    fonts,
    repoList,
    onQueryFonts,
    hideSnackbar,
    onLoadFonts,
    onPublishFont,
    onDeleteFont,
    currentFontRepo,
    inheritSuccess,
    publishSuccess,
    isDeleteSuccess,
    resetDeleteSuccess,
    fontsLoaded }: Props) {
    const [alert, setAlert] = useState(defaultAlert);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [fontId, setFontId] = useState('');
    const [fontToUpdate, setFontToUpdate] = useState();
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const onDelete = (id: string) => {
        onDeleteFont(id);
        setShowDeleteModal(false);
    };

    const onCloseClick = () => {
        setFontId('');
        setShowDeleteModal(false);
    };

    const showFontDeleteModal = (id: string) => {
        setFontId(id);
        setShowDeleteModal(true);
    };

    const getNoFontsMessage = () => {
        let emptyMessage = '';
        if (fontsLoaded) {
            if (searchText) {
                emptyMessage = 'No fonts available for the selected search criteria.';
            } else if (familyName) {
                emptyMessage = `No fonts available of ${familyName}. Please navigate back to the repository catalog to browse fonts`;
            } else if (!fonts.length) {
                emptyMessage = 'No fonts in the repository.';
            }
        }
        return emptyMessage;
    };

    useEffect(() => {
        if (showSnackbar) {
            setAlert({ isShown: true, message: 'Something went wrong, please try again.', style: 'danger', autoHide: false });
        }
    }, [showSnackbar]);

    const onHideSnackbar = () => hideSnackbar();

    useEffect(() => {
        onLoadFonts();
        onQueryFonts();
    }, [onLoadFonts, onQueryFonts, currentFontRepo.id, publishSuccess, inheritSuccess]);

    const onPublish = (id: string) => {
        onPublishFont(id);
    };

    const deleteModalFooter = () => (
        <div>
            <Button className='btn btn-danger' onClick={() => onDelete(fontId)}>Delete</Button>
            <Button onClick={onCloseClick}>Cancel</Button>
        </div>
    );

    const allFonts = (showDeleted: boolean) => (showDeleted ? fonts : fonts.filter(font => (font.links && (font.links.draft || font.links.published))));
    const hasDraft = (font: Font) => font.links && font.links.draft;
    const isPublished = (font: Font) => font.links && !font.links.draft && font.links.published;

    const onPublishClick = (font: Font) => {
        onPublish(font.id);
    };

    const onUpdateClick = (font: Font) => {
        setFontToUpdate(font);
        setShowUpdateModal(true);
    };

    const onUpdateCancel = () => setShowUpdateModal(false);

    const getCardActions = (font: Font): Action[] => {
        const cardActions = {
            Delete: {
                title: 'Delete font',
                icon: 'bin-1-l',
                onClick: () => showFontDeleteModal(font.id),
            },
            Publish: {
                title: 'Publish',
                icon: 'box-air-delivery-l',
                onClick: () => onPublishClick(font),
            },
            Update: {
                title: 'Update asset',
                icon: 'file-new-2-l',
                onClick: () => onUpdateClick(font),
            },
        };
        const actions = [];
        if (font.repositoryId === currentFontRepo.id && repoList.some(repo => repo.id === font.repositoryId)) {
            if (hasDraft(font)) {
                actions.push(cardActions.Delete);
            }
            if (getStatus(font) === Status.Draft) {
                actions.push(cardActions.Publish);
            }
            if (isPublished(font)) {
                actions.push(cardActions.Update);
            }
        }
        return actions;
    };

    const techsAvailable = allFonts(showDeletedFonts).reduce((acc: string[], font: Font) => (acc.includes(font.tech) ? acc : acc.concat(font.tech)), []);
    const techs = techsAvailable.sort((a: string, b: string) => (techListOrdered.indexOf(a) - techListOrdered.indexOf(b)));

    const renderFontCards = (fontList: Font[]) => fontList.map((font: Font) => (
        <FontCard
            repoId={currentFontRepo.id}
            textContent={textContent}
            key={font.id}
            size={size}
            fontRepoName={currentFontRepo.name}
            font={font}
            isDetailView={!!familyName}
            actions={getCardActions(font)}
        />
    ));

    const renderFontDetails = () => (
        <>
            {showUpdateModal && (
                <AddFontPopup
                    isOpen={showUpdateModal}
                    closePopup={onUpdateCancel}
                    font={fontToUpdate}
                />
            )}

            {techs && techs.length > 0 && techs.map(tech => (
                <div className='tech-section' key={tech}>
                    <div className='h7 tech-title'>{tech}</div>
                    <div className='fontlist-grid'>
                        {renderFontCards(allFonts(showDeletedFonts).filter((font: Font) => tech === font.tech))}
                    </div>
                </div>
            ))
            }
        </>
    );

    return (
        <>
            <Snackbar show={alert.isShown} bsStyle={alert.style} onHideSnackbar={onHideSnackbar} delay={alert.autoHide ? 4000 : undefined}>
                {alert.message}
            </Snackbar>

            <Snackbar
                show={isDeleteSuccess}
                bsStyle='success'
                onHideSnackbar={resetDeleteSuccess}
                delay={3000}
            >
                Successfully deleted font.
            </Snackbar>

            {showDeleteModal && (
                <Modal
                    show={showDeleteModal}
                    onRequestHide={onCloseClick}
                    title='Delete font'
                    footer={deleteModalFooter()}
                >
                Do you want to delete this font? This action cannot be undone.
                </Modal>
            )}

            {!familyName && !!fonts.length && (
                <div className='fontlist-grid'>
                    {renderFontCards(allFonts(showDeletedFonts))}
                </div>
            )}

            {familyName && renderFontDetails()}

            { !fonts.length && (
                <div className='no-results'>
                    {getNoFontsMessage()}
                </div>
            )}
        </>
    );
}
export default connect(
    (state: RootState) => ({
        isPublishing: state.font.isPublishing,
        showSnackbar: state.font.showSnackbar,
        publishSuccess: state.font.publishSuccess,
        isDeleteSuccess: state.font.deleteSuccess,
        fontsLoaded: state.font.loaded,
        inheritSuccess: state.repository.inheritSuccess,
        repoList: state.repository.repoList,
    }),
    {
        onLoadFonts: slice.actions.onLoadFonts,
        onPublishFont: slice.actions.onPublishFont,
        onDeleteFont: slice.actions.onDeleteFont,
        onQueryFonts: slice.actions.onQueryFonts,
        hideSnackbar: slice.actions.hideSnackbar,
        resetDeleteSuccess: slice.actions.resetDeleteSuccess,
    },
)(FontList);
