import { createSlice, PayloadAction } from 'redux-starter-kit';
import { RootState } from '../../state/rootReducer';
import { FontRepo } from '../../clients/fontClient';

export interface Repository {
    isSaveSuccess: boolean;
    inheritSuccess: boolean;
    fontsInherited: boolean | undefined;
    showSnackbar: boolean;
    isSaving: boolean;
    isEditing: boolean;
    isLoading: boolean;
    isError: boolean;
    currentFontRepo: FontRepo;
    isLoadingRepo: boolean;
    repoList: FontRepo[];
    isRepoFetchCompleted: boolean;
}

const fontRepo: FontRepo = {
    name: '',
    id: '',
    inheritedRepositories: null,
};
export interface RepoPayload {
    name: string;
    id?: string;
    inheritedRepositories?: string[];
}

export const INITIAL_STATE: Repository = {
    isSaveSuccess: false,
    inheritSuccess: false,
    fontsInherited: undefined,
    showSnackbar: false,
    isSaving: false,
    isEditing: false,
    isLoading: false,
    isError: false,
    currentFontRepo: fontRepo,
    isLoadingRepo: false,
    repoList: [],
    isRepoFetchCompleted: false,
};

const slice = createSlice({
    slice: 'repository',
    initialState: INITIAL_STATE,
    reducers: {
        onSaveRepoName: (state, { payload }: PayloadAction<RepoPayload>) => {
            state.isSaving = true;
            state.isSaveSuccess = false;
            state.isError = false;
            state.isLoading = true;
        },
        onInheritRepo: (state) => {
            state.isLoading = true;
            state.inheritSuccess = false;
        },
        inheritSuccess: (state, { payload }) => {
            state.isLoading = false;
            state.inheritSuccess = true;
            state.showSnackbar = true;
            state.fontsInherited = true;
            state.currentFontRepo = payload;
            state.isLoading = false;
        },
        inheritFailed: (state) => {
            state.isLoading = false;
            state.inheritSuccess = false;
            state.showSnackbar = true;
            state.isLoading = false;
        },
        hideSnackbar: (state) => {
            state.showSnackbar = false;
            state.inheritSuccess = false;
        },
        saveSuccess: (state, { payload }: PayloadAction<FontRepo>) => {
            state.isSaveSuccess = true;
            state.isSaving = false;
            state.isError = false;
            state.currentFontRepo = payload;
            state.isLoading = false;
        },
        saveFailed: (state) => {
            state.isSaving = false;
            state.isSaveSuccess = false;
            state.isError = true;
            state.isLoading = false;
        },
        resetSuccess: (state) => {
            state.isSaveSuccess = false;
            state.isLoading = false;
        },
        resetError: (state) => {
            state.isError = false;
        },
        startLoadingRepo: (state, { payload }: PayloadAction<string>) => { state.isLoading = true; state.fontsInherited = undefined; },
        loadSuccess: (state, { payload }: PayloadAction<FontRepo>) => {
            state.currentFontRepo = payload;
            state.isLoading = false;
        },
        loadFailed: (state) => { state.isLoading = false; },
        startFetchingRepoList: (state) => { state.isLoadingRepo = true; },
        fetchSuccess: (state, { payload }: PayloadAction<FontRepo[]>) => {
            state.isLoadingRepo = false;
            state.repoList = payload.sort((firstRepo, secondRepo) => firstRepo.name.localeCompare(secondRepo.name));
            state.isRepoFetchCompleted = true;
        },
        fetchFailed: (state) => {
            state.repoList = [];
            state.isLoadingRepo = false;
            state.isRepoFetchCompleted = true;
        },
    },
});

export function getCurrentRepoId(state: RootState) {
    return state.repository.currentFontRepo.id;
}

export function getCurrentRepo(state: RootState) {
    return state.repository.currentFontRepo;
}

export const { reducer } = slice;

export default slice;
