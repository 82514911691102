import { configureStore } from 'redux-starter-kit';
import sagaMiddlewareFactory from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from './rootReducer';
import sagaRoot from './sagaRoot';
import { basename } from '../settings';

const sagaMiddleware = sagaMiddlewareFactory();

export const history = createBrowserHistory({
    basename,
});

const routeMiddleware = routerMiddleware(history);


export default function createStore() {
    const store = configureStore({
        reducer: rootReducer(history),
        middleware: [routeMiddleware, sagaMiddleware],
    });

    sagaMiddleware.run(sagaRoot);

    return store;
}
