import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Snackbar, shapes, Toggle } from '@cimpress/react-components';

import AddFont from './addFont/AddFont';
import FontText, { DEFAULT_FONT_PREVIEW_TEXT } from './fontText/FontText';
import SearchFont from './searchFont/SearchFont';
import FontSizeSelector from '../../components/fontSizeSelector';
import Repository from '../repository/Repository';
import RepositoryActions from '../repository/RepositoryActions';
import FontList from './fontList/FontList';
import { RootState } from '../../state/rootReducer';
import { GroupedFont } from '../../fonts/saga';
import { FontSizeOption } from '../../components/fontSizeSelector/FontSizeSelector';
import './catalog.scss';
import { getQueryVariable } from '../../urlUtil';
import { validFontStyles } from './addFontPopup/AddFontPopup';
import { Font, FontRepo } from '../../clients/fontClient';
import { getFontList } from './fontList/getFontList';
import { Tenant } from '../../components/tenant';

export enum SearchType {
    fontStyle = 'fontStyle',
    fontFamily = 'fontFamily'
}

interface Match {
    params: {
        id: string;
    };
}

interface Props {
    match: Match;
    location: {
        search: string;
    };
    availableTenants: Tenant[];
    tenantsLoaded: boolean;
    fontsLoading: boolean;
    fontsLoaded: boolean;
    fontDeleting: boolean;
    repoLoading: boolean;
    groupedFonts: GroupedFont[];
    currentFontRepo: FontRepo;
    repoList: FontRepo[];
    currentTenant: Tenant | undefined;
    isRepoFetchCompleted: boolean;
}

const { Spinner } = shapes;

const FONT_SIZES = [8, 12, 14, 20, 24, 32, 40, 64, 96, 120, 184, 280, 400];
const DEFAULT_FONT_SIZE = FONT_SIZES[4];

// Util function to populate a select options array
const sizes: FontSizeOption[] = ((fontSizes: number[]) => fontSizes.map(fSize => ({
    value: fSize,
    label: `${fSize}pt`,
})))(FONT_SIZES);

const getSearchType = (text: string) => (validFontStyles.map((s => s.toLocaleLowerCase())).includes(text.toLocaleLowerCase()) ? SearchType.fontStyle : SearchType.fontFamily);

const hasManageFontPermission = (currentTenant: Tenant) => currentTenant && currentTenant.permissions && currentTenant.permissions.fonts && currentTenant.permissions.fonts.canManage;

function Catalog({ currentFontRepo, groupedFonts, match, location, availableTenants, tenantsLoaded, fontsLoading, repoLoading, fontDeleting, repoList, currentTenant, fontsLoaded }: Props) {
    const familyName = getQueryVariable('familyName', location.search) || '';
    const searchText = getQueryVariable('search', location.search) || '';
    const [searchType, setSearchType] = useState<SearchType | undefined>(undefined);
    const [fonts, setFonts] = useState<Font[]>([]);
    const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
    const [previewText, setPreviewText] = useState(DEFAULT_FONT_PREVIEW_TEXT);
    const [reposFetched, setReposFetched] = useState(false);
    const [showDeleted, setShowDeleted] = useState(false);

    const onReposLoaded = () => setReposFetched(true);

    const onFontSizeChange = (size: number) => {
        setFontSize(size);
    };

    const onTextChange = (text: string) => {
        setPreviewText(text);
    };

    const toggleShowDeletedFonts = () => {
        setShowDeleted(!showDeleted);
    };

    useEffect(() => {
        if (location.search) {
            setSearchType(getSearchType(searchText));
        }
    }, [location.search, searchText]);

    useEffect(() => {
        setFonts(getFontList({ familyName, groupedFonts, searchText, searchType }));
    }, [familyName, groupedFonts, searchText, searchType]);

    return (
        <>
            { ((tenantsLoaded && availableTenants.length === 0) || (currentTenant && !hasManageFontPermission(currentTenant))) && (
                <Snackbar
                    show={availableTenants.length === 0 || (currentTenant && !hasManageFontPermission(currentTenant))}
                    bsStyle='danger'
                >
                    You have no COAM permission. Please contact your tenant admin.
                </Snackbar>
            )
            }
            <div className='container landing'>
                { tenantsLoaded && availableTenants.length > 0 && currentTenant && hasManageFontPermission(currentTenant) && (
                <>
                    <div className='catalog__header'>
                        <Repository id={match.params.id} onLoadRepo={onReposLoaded} limited={!!familyName} />
                        {(fontsLoaded && repoList.length > 0) && (
                            <div className='fonts-toggle'>
                                Show deleted fonts: <Toggle on={showDeleted} onClick={toggleShowDeletedFonts} size='sm' />
                            </div>
                        )}
                    </div>
                    {(fontsLoading || fontDeleting || !reposFetched || repoLoading) && <Spinner className='catalog-spinner' />}
                    { ((repoList && repoList.length > 0) || (match.params.id)) && (
                        <Card className={`${fontsLoading || fontDeleting || !reposFetched || repoLoading ? 'modal-backdrop in' : ''} `}>
                            <div className='action-container'>
                                {familyName ? (
                                    <div className='family-label lead'>
                                        Family Name: {familyName}
                                    </div>
                                ) : <RepositoryActions currentFontRepo={currentFontRepo} />
                                }
                                <div className={!fonts.length ? 'action-hidden' : 'text-manipulate-actions'}>
                                    <FontSizeSelector sizes={sizes} defaultSize={DEFAULT_FONT_SIZE} onFontSizeChange={onFontSizeChange} />
                                </div>
                                <div className={!fonts.length ? 'action-hidden' : 'text-manipulate-actions'}>
                                    <FontText onTextChange={onTextChange} />
                                </div>
                                <SearchFont currentFontRepo={currentFontRepo} familyName={familyName} searchText={searchText} searchType={searchType} />
                                {!familyName && <AddFont />}
                            </div>
                            {currentFontRepo.id && (
                                <FontList
                                    size={fontSize}
                                    textContent={previewText}
                                    fonts={fonts}
                                    currentFontRepo={currentFontRepo}
                                    familyName={familyName}
                                    searchText={searchText}
                                    showDeletedFonts={showDeleted}
                                />
                            )}
                        </Card>
                    )}
                </>
                )}
            </div>
        </>
    );
}

export default connect(
    (state: RootState) => ({
        currentFontRepo: state.repository.currentFontRepo,
        groupedFonts: state.font.groupedFonts,
        fontsLoading: state.font.isLoading,
        fontsLoaded: state.font.loaded,
        fontDeleting: state.font.isDeleting,
        repoLoading: state.repository.isLoading,
        isRepoFetchCompleted: state.repository.isRepoFetchCompleted,
        repoList: state.repository.repoList,
        currentTenant: state.tenantInfo.tenant,
        availableTenants: state.tenantInfo.availableTenants,
        tenantsLoaded: state.tenantInfo.tenantsLoaded,
    }),
)(Catalog);
