import React from 'react';

import { DetailsSection } from './section/DetailsSection';
import { PreviewSection } from './section/PreviewSection';
import { FooterSection } from './section/FooterSection';
import { Font } from '../../clients/fontClient';

import './fontCard.scss';
import { Actions, Action } from './section/Actions';

interface Props {
    repoId: string;
    textContent: string;
    font: Font;
    size: number;
    fontRepoName: string;
    isDetailView: boolean;
    isLoading?: boolean;
    actions: Action[];
}

export enum Status {
    Draft= 'Draft',
    Published= 'Published',
    NoAsset= 'No Assets',
}

const getStatus = (font: Font) => {
    if (font.links && font.links.draft) {
        return Status.Draft;
    } if (font.links && font.links.published) {
        return Status.Published;
    }
    return Status.NoAsset;
};

export function FontCard({ repoId, textContent, font, fontRepoName, size, isDetailView, actions }: Props) {
    const deletedFontCssClass = isDetailView ? 'deleted-background-details' : 'deleted-background';
    return (
        <div className={`card-container ${Status.NoAsset === getStatus(font) ? deletedFontCssClass : ''}`}>
            {isDetailView && !(Status.NoAsset === getStatus(font)) && <Actions actions={actions} />}
            <PreviewSection
                content={textContent}
                fontFamily={font.familyName}
                fontStyle={font.style}
                size={size}
                links={font.links}
                repoId={font.repositoryId}
                canDownloadFontFile={font.license === 'Open'}
                status={getStatus(font)}
            />
            <DetailsSection
                repoId={repoId}
                fontFamilyName={font.familyName}
                repoName={fontRepoName}
                existingStyles={font.existingStyles}
                style={isDetailView ? font.style : undefined}
            />
            {isDetailView && (
                <FooterSection
                    status={getStatus(font)}
                />
            )
            }
        </div>
    );
}
