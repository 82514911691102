import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { logError } from '../loggingManager';
import { auth } from '../components/AuthContext';

export const axiosWithAuth = axios.create();

axiosWithAuth.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.Authorization = `Bearer ${auth.getAccessToken()}`; // eslint-disable-line no-param-reassign
    return config;
}, async error => Promise.reject(error));

axiosWithAuth.interceptors.response.use(response => response, async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
        logError('You are not authorized');
    }
    return Promise.reject(error);
});
