import { apiPrefix } from '../../src/settings';
import { axiosWithAuth } from './interceptors';
import { logError } from '../loggingManager';

export const FONT_BASE_URL = `https://${apiPrefix}fonts.documents.cimpress.io/v1/repositories`;

interface Tenant {
    tenantId: string;
    tenantType: string;
}

export interface FontRepo {
    name: string;
    id: string;
    inheritedRepositories?: string[] | null;
    tenant?: {id: string};
}

export interface FontProperty {
    [property: string]: string;
}

export interface FontToAdd {
    familyName: string;
    style: string;
    technology: string;
    license: string;
}

interface LinkedAsset {
    measurementAssetId: string;
    renderingAssetId: string;
}

export interface Links {
    published?: {
        [key: string]: LinkedAsset;
    };
    draft?: {
        [key: string]: LinkedAsset;
    };
}

export interface Asset {
    id: string;
    renderTech: string;
    legacyMetadata: Metadata;
    extension: string;
}

export interface Metadata {
    fontId: string;
    fontName: string;
}

export interface Font {
    id: string;
    repositoryId: string;
    familyName: string;
    style: string;
    tech: string;
    license: string;
    links?: Links;
    assets?: Asset[];
    legacyMetadata: Metadata;
    existingStyles?: string[];
}

interface RepoInfo {
    tenant: {
        id: string;
        type: string;
    };
    inheritedRepositories?: string[];
}

interface RepoCreatePayload {
    name: string;
    inheritedRepositories?: string[] | null;
    tenant: {
        id: string;
        type: string;
    };
}

function updateRepository(repoInfo: RepoInfo, repoId: string) {
    return axiosWithAuth.patch<FontRepo>(`${FONT_BASE_URL}/${repoId}`, repoInfo)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to inherit master repo!');
            throw error;
        });
}

export async function fetchRepo(repoId: string) {
    return axiosWithAuth.get<FontRepo>(`${FONT_BASE_URL}/${repoId}`)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to fetch repo!');
            throw error;
        });
}

export async function updateRepo(name: string, tenant: Tenant, repoId: string) {
    const repoInfo = {
        name,
        tenant: {
            id: tenant.tenantId,
            type: tenant.tenantType,
        },
    };
    return updateRepository(repoInfo, repoId);
}
export async function inheritRepos(tenant: Tenant, repoId: string, inheritedRepositories: string[]) {
    const repoInfo = {
        tenant: {
            id: tenant.tenantId,
            type: tenant.tenantType,
        },
        inheritedRepositories,
    };
    return updateRepository(repoInfo, repoId);
}

export async function saveRepo(repo: RepoCreatePayload) {
    return axiosWithAuth.post<FontRepo>(FONT_BASE_URL, repo)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to save repo!');
            throw error;
        });
}

export async function addFont(fontToAdd: FontToAdd, repoId: string) {
    return axiosWithAuth.post<FontRepo>(`${FONT_BASE_URL}/${repoId}/fonts`, fontToAdd)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to add font!');
            throw error;
        });
}

export async function deleteFont(repoId: string, fontId: string) {
    return axiosWithAuth.delete(`${FONT_BASE_URL}/${repoId}/fonts/${fontId}/drafts`)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to delete font!');
            throw error;
        });
}

export async function addFontFile(fontId: string, fontFile: File, repoId: string) {
    const data = new FormData();
    data.append('file', fontFile);
    return axiosWithAuth.post<FontRepo>(`${FONT_BASE_URL}/${repoId}/fonts/${fontId}/assets`, data)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to add font file!');
            throw error;
        });
}

export async function getAllFontsDetails(repoId: string, publicView: boolean) {
    return axiosWithAuth.get<Font[]>(`${FONT_BASE_URL}/${repoId}/fonts${publicView ? '?view=Public' : ''}`)
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to load fonts!');
            throw error;
        });
}

export async function fetchAllRepositories({ tenantId, tenantType }: Tenant) {
    const params = {
        tenantId,
        tenantType,
    };
    return axiosWithAuth.get<Font[]>(FONT_BASE_URL, { params })
        .then(response => response.data)
        .catch((error) => {
            logError('Failed to fetch repositores!');
            throw error;
        });
}

export async function publishFont(repoId: string, fontId: string) {
    return axiosWithAuth.post(`${FONT_BASE_URL}/${repoId}/fonts/${fontId}/drafts:promote`)
        .then(response => response.status)
        .catch((error) => {
            logError('Failed to publish font!');
            throw error;
        });
}
