import React, { useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, TextField, Snackbar, Checkbox } from '@cimpress/react-components';

import './repository.scss';

import { RootState } from '../../state/rootReducer';
import slice, { RepoPayload } from './slice';
import { INHERIT_REPOSITORY_ID } from './saga';

interface Props {
    isOpen: boolean;
    isSaveSuccess: boolean;
    isError: boolean;
    resetSuccess(): void;
    resetError(): void;
    resetIsOpen(): void;
    onSaveRepoName(payload: RepoPayload): void;
}

export function Create({ isOpen, isSaveSuccess, isError, resetIsOpen, resetSuccess, resetError, onSaveRepoName }: Props) {
    const [repositoryName, setRepositoryName] = useState('');
    const [inheritFromMaster, setInheritFromMaster] = useState(false);
    const [errorText, setErrorText] = useState('');

    const setName = (e: ChangeEvent<HTMLInputElement>) => {
        setErrorText('');
        setRepositoryName(e.currentTarget.value);
    };

    const onModalClose = () => {
        setRepositoryName('');
        setErrorText('');
        setInheritFromMaster(false);
        resetIsOpen();
    };

    const onClickSave = () => {
        if (!repositoryName) {
            setErrorText('This is a required field.');
            return;
        }
        onSaveRepoName({ name: repositoryName, inheritedRepositories: inheritFromMaster ? [INHERIT_REPOSITORY_ID] : undefined });
        resetIsOpen();
        setRepositoryName('');
        setErrorText('');
        setInheritFromMaster(false);
    };

    const generateMessage = (): string => {
        if (isError) {
            return 'Failed to save repository.';
        }
        return 'Repository saved successfully!';
    };

    const toggleInheritCheckbox = () => setInheritFromMaster(!inheritFromMaster);
    return (
        <>
            <Snackbar
                show={isError || isSaveSuccess}
                bsStyle={isError ? 'danger' : 'success'}
                onHideSnackbar={isError ? resetError : resetSuccess}
                delay={isError ? 0 : 3000}
            >
                {generateMessage()}
            </Snackbar>
            <Modal
                className='create-repository'
                show={isOpen}
                title='Create a new repository'
                closeButton={true}
                onRequestHide={onModalClose}
                footer={(
                    <div className='create-repository__actions'>
                        <Button type='primary' onClick={onClickSave}>Save</Button>
                        <Button onClick={onModalClose}>Cancel</Button>
                    </div>
                )}
            >
                <div className='create-repository__intro'>Create a repository to house all your fonts. A Font Repository key is required for using Fonts on Designer.</div>
                <TextField
                    className='create-repository__input'
                    name='required'
                    label='Font Repository Name'
                    value={repositoryName}
                    onChange={setName}
                    required={true}
                    helpText={errorText}
                    bsStyle={errorText ? 'error' : ''}
                />
                <Checkbox
                    label='Copy fonts from master repository'
                    checked={inheritFromMaster}
                    onChange={toggleInheritCheckbox}
                />

            </Modal>
        </>
    );
}

export default connect(
    (state: RootState) => ({
        isSaveSuccess: state.repository.isSaveSuccess,
        isError: state.repository.isError,
    }),
    {
        onSaveRepoName: slice.actions.onSaveRepoName,
        resetSuccess: slice.actions.resetSuccess,
        resetError: slice.actions.resetError,
    },
)(Create);
