import React, { useState, useEffect } from 'react';
import { Tenant, getAvailableTenants } from './tenantClient';
import TenantSelectorModal from './tenantSelectorModal';

import './tenantSelector.scss';

interface Props {
    currentTenant: Tenant | undefined;
    show: boolean;
    message?: string;
    sub: string;
    accessToken: string;
    onSaveTenant(tenant: Tenant): void;
    onLoadTenants(tenants: Tenant[]): void;
}

export default function TenantSelector({ currentTenant, message, sub, accessToken, onSaveTenant, onLoadTenants, show }: Props) {
    const [open, setOpen] = useState(show);
    const [availableTenants, setAvailableTenants] = useState<Tenant[]>([]);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const onSave = (tenant: Tenant) => {
        onSaveTenant(tenant);
        setOpen(false);
    };

    useEffect(() => {
        if (sub && accessToken) {
            getAvailableTenants(sub, accessToken).then((tenants: Tenant[]) => {
                onLoadTenants(tenants);
                setAvailableTenants(tenants);
            }).catch(() => {
                onLoadTenants([]);
            });
        }
    }, [sub, accessToken, onLoadTenants]);

    useEffect(() => {
        setOpen(show);
    }, [show]);

    const isOpen = () => open;

    return (
        <div className='tenant'>
            {(availableTenants.length > 0) && (
                <div className='tenant__wrapper'>
                    <span className='tenant__label'>Tenant:</span>
                    <button type='button' className='btn btn-link' onClick={onOpen}>
                        {currentTenant ? currentTenant.tenantDisplayName : 'None'}
                    </button>
                </div>
            )}
            {(currentTenant || isOpen()) && (
                <TenantSelectorModal
                    currentTenant={currentTenant}
                    availableTenants={availableTenants}
                    open={open}
                    onClose={onClose}
                    onSave={onSave}
                    message={message}
                />
            )}
        </div>
    );
}
