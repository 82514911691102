import { Modal } from '@cimpress/react-components';
import React, { useState } from 'react';

import useInterval from './useInterval';

interface Props {
    isOpen: boolean;
    setClose(): void;
}

export default function AuthExpirationModal({ isOpen, setClose }: Props) {
    const [count, setCount] = useState(5);

    useInterval(() => {
        if (count === 1) {
            setCount(5);
            setClose();
        } else {
            setCount(count - 1);
        }
    }, 1000, isOpen);

    return (
        <Modal
            bsStyle='danger'
            show={isOpen}
            title='Session Expired'
        >
            Your session has expired. We are triggering a refresh in <b>{count}</b> seconds...
        </Modal>
    );
}
