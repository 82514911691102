import React, { useState } from 'react';
import { Modal, Button, Select, Label } from '@cimpress/react-components';
import { Tenant } from './tenantClient';

interface Props {
    currentTenant?: Tenant;
    availableTenants: Tenant[];
    open: boolean;
    message?: string;
    onClose(): void;
    onSave(tenant: Tenant): void;
}

const generateTenantHash = (tenant: Tenant) => `${tenant.tenantType}-${tenant.tenantId}`;

const generateTenantLabel = (tenant: Tenant) => `${tenant.tenantType}: ${tenant.tenantDisplayName}`;

export default function TenantSelectorModal({ currentTenant, availableTenants, open, message, onClose, onSave }: Props) {
    const [selectedTenant, setSelectedTenant] = useState(currentTenant);
    const requiresSelection = currentTenant === undefined;

    const onSaveClick = () => {
        if (selectedTenant) {
            onSave(selectedTenant);
        }
    };

    const onCloseClick = () => {
        setSelectedTenant(currentTenant); // if they aren't saving the currently selected one, we should reset it
        onClose();
    };

    const footer = (
        <div>
            {!requiresSelection && (<Button onClick={onCloseClick}>Cancel</Button>)}
            <Button type='primary' onClick={onSaveClick}>Set As Current Tenant</Button>
        </div>
    );

    const onChange = ({ value }: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        const matchingTenant = availableTenants.find(tenant => generateTenantHash(tenant) === value);
        if (matchingTenant) {
            setSelectedTenant(matchingTenant);
        }
    };

    return (
        <Modal
            className='tenant-selector'
            show={open}
            onRequestHide={onClose}
            closeButton={!requiresSelection}
            title='Select a Tenant'
            footer={footer}
        >
            <div className='tenant-selector__text'>
                {message}
            </div>
            <div>
                <Select
                    label='Select a Tenant'
                    value={selectedTenant ? generateTenantHash(selectedTenant) : currentTenant}
                    options={availableTenants.map(tenant => ({ value: generateTenantHash(tenant), label: generateTenantLabel(tenant) }))}
                    onChange={onChange}
                    tether={true}
                />
            </div>
            {selectedTenant && (
                <div>
                    <span>Permissions: </span>
                    <Label text='Manage Fonts' type={selectedTenant.permissions.fonts && selectedTenant.permissions.fonts.canManage ? 'success' : 'default'} />
                </div>
            )}
        </Modal>
    );
}
