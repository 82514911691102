import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Icon, colors, Dropdown, Card } from '@cimpress/react-components';
import Create from './Create';
import slice from './slice';
import { RootState } from '../../state/rootReducer';
import './repository.scss';
import { Tenant } from '../../components/tenant';
import { FontRepo } from '../../clients/fontClient';

interface Props {
    id: string;
    currentFontRepo: FontRepo;
    repoList: FontRepo[];
    isRepoLoading: boolean;
    currentTenant: Tenant | undefined;
    startLoadingRepo(payload: string): void;
    startFetchingRepoList(): void;
    onLoadRepo(): void;
    isRepoFetchCompleted: boolean;
    limited?: boolean;
}

const { ocean } = colors;

export function Repository({ id,
    currentFontRepo,
    repoList,
    startLoadingRepo,
    startFetchingRepoList,
    isRepoLoading,
    currentTenant,
    isRepoFetchCompleted,
    limited,
    onLoadRepo }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const onClickCreate = () => setIsOpen(true);

    const generateRepoLink = (repository: FontRepo) => (
        <Link key={repository.id} className='btn-link' to={`/repository/${repository.id}`}>
            {repository.name}
        </Link>
    );

    const resetIsOpen = () => setIsOpen(false);

    const renderCreateRepoBtn = (primary?: boolean) => (
        <Button className='create-repo-name' onClick={onClickCreate} type={primary ? 'primary' : 'link'}>
            {!primary && (
                <Icon
                    name='add-1-l'
                    size='lg'
                    color={ocean.darker}
                    style={{
                        marginLeft: '20px',
                        marginRight: '10px',
                        cursor: 'pointer',
                    }}
                />
            )}
            Create New Repository
        </Button>
    );

    useEffect(() => {
        id && startLoadingRepo(id);
    }, [startLoadingRepo, id]);

    useEffect(() => {
        startFetchingRepoList();
    }, [startFetchingRepoList, currentFontRepo, currentTenant]);

    useEffect(() => {
        if (!isRepoLoading) {
            onLoadRepo();
        }
    }, [isRepoLoading, onLoadRepo]);

    if (!id
        && repoList
        && repoList.length
        && currentTenant
        && repoList[0]
        && repoList[0].tenant
        && (currentTenant.tenantId === repoList[0].tenant.id)) {
        return <Redirect to={{ pathname: `/repository/${repoList[0].id}` }} push={true} />;
    }

    return (
        <>
            {repoList && !!repoList.length ? (
                <div className='repository'>
                    {limited ? (
                        <Link key={currentFontRepo.id} className='btn repository_btn-back dropdown-toggle ' to={`/repository/${currentFontRepo.id}`}>
                            {currentFontRepo.name}
                            <Icon
                                name='arrow-circle-left-3-f'
                                size='lg'
                                color={ocean.darker}
                                style={{
                                    position: 'absolute',
                                    left: '318px',
                                    top: '15px',
                                }}
                            />
                        </Link>
                    ) : (
                        <>
                            <Dropdown title={currentFontRepo.name} className='repository__dropdown'>
                                {repoList.map(repo => (generateRepoLink(repo)))}
                            </Dropdown>
                            {renderCreateRepoBtn()}
                        </>
                    )}

                </div>
            ) : (
                <div className={isRepoFetchCompleted && !id ? 'repository-info' : 'repository-info-hidden'}>
                    <Card>
                        <div className='repository-message'><span>Create a font repository and start adding fonts to it.</span></div>
                        <div>{renderCreateRepoBtn(true)}</div>
                    </Card>
                </div>
            )}
            <Create
                isOpen={isOpen}
                resetIsOpen={resetIsOpen}
            />
        </>
    );
}

export default connect(
    (state: RootState) => ({
        currentFontRepo: state.repository.currentFontRepo,
        repoList: state.repository.repoList,
        isRepoLoading: state.repository.isLoading,
        isRepoFetchCompleted: state.repository.isRepoFetchCompleted,
        currentTenant: state.tenantInfo.tenant,
    }),
    {
        startLoadingRepo: slice.actions.startLoadingRepo,
        startFetchingRepoList: slice.actions.startFetchingRepoList,
    },
)(Repository);
