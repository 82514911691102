import { takeEvery, put, call, select } from 'redux-saga/effects';
import { PayloadAction } from 'redux-starter-kit';

import { addFont, addFontFile, getAllFontsDetails, Font, FontToAdd } from '../../../clients/fontClient';
import slice from './slice';
import { getCurrentRepoId } from '../../repository/slice';
import fontListSlice from '../../../fonts/slice';
import { FontProperties } from './AddFontPopup';

function generateFontObj(fontProperties: FontProperties[]): FontToAdd {
    const fontObj: FontToAdd = {
        familyName: '',
        style: '',
        technology: '',
        license: '',
    };
    fontProperties.forEach((property) => {
        if (property.type === 'familyName') {
            fontObj.familyName = property.value;
        } else if (property.type === 'style') {
            fontObj.style = property.value;
        } else if (property.type === 'technology') {
            fontObj.technology = property.value;
        } else if (property.type === 'license') {
            fontObj.license = property.value;
        }
    });
    return fontObj;
}

export function* onAddFont(action: PayloadAction) {
    const repoId = (yield select(getCurrentRepoId)) as string;
    const fontProperties = action.payload;
    try {
        const allFonts = (yield call(getAllFontsDetails, repoId, true)) as Font[];
        const fontObj = generateFontObj(fontProperties.properties);
        const existingFont = allFonts.find(font => font.familyName === fontObj.familyName && font.style === fontObj.style && font.tech === fontObj.technology);
        let fontId = existingFont ? existingFont.id : undefined;
        if (!existingFont) {
            const fontAddedProperties = yield call(addFont, fontObj, repoId);
            fontId = fontAddedProperties ? fontAddedProperties.id : undefined;
        }
        if (fontId && (!existingFont || !existingFont.links || !existingFont.links.draft)) {
            const propertiesWithFile = yield call(addFontFile, fontId, fontProperties.file, repoId);
            if (existingFont && existingFont.links && existingFont.links.published) {
                yield put(fontListSlice.actions.onPublishFont(fontId));
            }
            yield put(fontListSlice.actions.onQueryFonts());
            yield put(slice.actions.addFontSuccess(propertiesWithFile));
        } else {
            yield put(slice.actions.addFontFailed());
        }
    } catch {
        yield put(slice.actions.addFontFailed());
    }
}

export default function* repositorySaga() {
    return yield takeEvery(slice.actions.onAddFont, onAddFont);
}
