import React, { useState } from 'react';
import { Select } from '@cimpress/react-components';

export interface FontSizeOption {
    value: number;
    label: string;
}

interface Props {
    sizes: FontSizeOption[];
    defaultSize: number;
    onFontSizeChange(size: number): void;
}

export function FontSizeSelector({ sizes, defaultSize, onFontSizeChange }: Props) {
    const [fontSize, setFontSize] = useState(defaultSize);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const changeSize = (selection: any) => {
        const selectedFontSize = selection ? selection.value : defaultSize;
        setFontSize(selectedFontSize);
        onFontSizeChange(selectedFontSize);
    };

    return (
        <Select
            label='Font Size'
            value={fontSize}
            options={sizes}
            tether={true}
            onChange={changeSize}
        />
    );
}
