import React, { useState, ChangeEvent, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { TextField, Button, Icon } from '@cimpress/react-components';

import './searchFont.scss';
import { SearchType } from '../Catalog';
import { FontRepo } from '../../../clients/fontClient';

interface Props {
    familyName: string;
    searchText: string;
    searchType: SearchType | undefined;
    currentFontRepo: FontRepo;
}

export default function SearchFont({ familyName, searchText, searchType, currentFontRepo }: Props) {
    const [text, setSearchText] = useState('');
    const [redirectLink, setRedirectLink] = useState('');

    useEffect(() => {
        setSearchText(searchText && !familyName ? searchText : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyName]);

    const repoPath = `/repository/${currentFontRepo.id}`;

    const clearText = () => {
        setSearchText('');
        setRedirectLink(familyName ? `${repoPath}?familyName=${encodeURIComponent(familyName)}` : repoPath);
    };

    const onChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value);

    const searchFont = () => {
        setRedirectLink(`${repoPath}?search=${text}`);
    };

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const keyCode = event.which;
        if (keyCode === 13) {
            searchFont();
        }
    };

    return (
        <div className='search-container'>
            <div className='search-container__search-value'>
                <TextField
                    label={familyName ? 'Search by style' : 'Search by name or style'}
                    className='search-text'
                    onKeyPress={handleEnterKeyPress}
                    value={text}
                    onChange={onChangeSearchText}
                    rightAddon={(
                        <>
                            {text
                                && (
                                    <Button className='search-clear-button' onClick={clearText}>
                                        <Icon name='remove-circle-1-f' />
                                    </Button>
                                )
                            }
                            <Button className='search-button' onClick={searchFont} disabled={!text}>
                                <Icon name='search-f' />
                            </Button>
                        </>
                    )}
                />
                {redirectLink && <Redirect to={redirectLink} />}
            </div>
        </div>
    );
}
