import { createSlice, PayloadAction } from 'redux-starter-kit';
import { RootState } from '../../state/rootReducer';
import { Tenant } from '../tenant';


interface TenantInfo {
    tenant: Tenant | undefined;
    tenantsLoaded: boolean;
    availableTenants: Tenant[];
}

const INITIAL_STATE: TenantInfo = {
    tenant: undefined,
    tenantsLoaded: false,
    availableTenants: [],
};

const slice = createSlice({
    slice: 'tenantInfo',
    initialState: INITIAL_STATE,
    reducers: {
        onSaveTenant: (state, { payload }: PayloadAction<Tenant>) => {
            state.tenant = payload;
        },
        onLoadTenants: (state, { payload }: PayloadAction<Tenant[]>) => {
            state.tenantsLoaded = true;
            state.availableTenants = payload;
        },
    },
});

export function getCurrentTenant(state: RootState) {
    return state.tenantInfo.tenant;
}

export const { reducer } = slice;

export default slice;
