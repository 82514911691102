import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from '@cimpress-technology/react-platform-header/lib/Header';
import { push, Push } from 'connected-react-router';
import { useAuth } from '../AuthContext';
import TenantSelector, { Tenant, getLastTenant, setLastTenant } from '../tenant';
import slice from './slice';
import { RootState } from '../../state/rootReducer';

interface Props {
    tenantsLoaded: boolean;
    availableTenants: Tenant[];
    connectedPush: Push;
    onSaveCurrentTenant(payload: Tenant): void;
    onLoadTenants(payload: Tenant[]): void;
}


export function AppHeader({ tenantsLoaded, availableTenants, connectedPush, onSaveCurrentTenant, onLoadTenants }: Props) {
    const { auth, isLoggedIn } = useAuth();
    const [currentTenant, setCurrentTenant] = useState<Tenant | undefined>();
    const [currentTenantLoaded, setCurrentTenantLoaded] = useState(false);

    useEffect(() => {
        const lastTenant = getLastTenant();
        setCurrentTenant(lastTenant || undefined);
        lastTenant && onSaveCurrentTenant(lastTenant);
        setCurrentTenantLoaded(true);
    }, [onSaveCurrentTenant]);

    function login() {
        auth.login();
    }

    function logout() {
        window.onbeforeunload = null;
        auth.logout('/');
    }

    function onSaveTenant(tenant: Tenant) {
        setCurrentTenant(tenant);
        setLastTenant(tenant);
        onSaveCurrentTenant(tenant);
        connectedPush('/');
    }

    function getProfile() {
        return isLoggedIn ? auth.getProfile() : {};
    }

    return (
        <Header
            appTitle='Font Manager'
            appLinks={
                [
                    isLoggedIn && (
                        <TenantSelector
                            message='Before you begin, you will need to choose a tenant. You can reselect your current tenant at any time via the header.'
                            currentTenant={currentTenant}
                            show={tenantsLoaded && availableTenants.length > 0 && currentTenantLoaded && !(currentTenant && currentTenant.tenantId)}
                            onSaveTenant={onSaveTenant}
                            sub={auth.getProfile().sub}
                            accessToken={auth.getAccessToken()}
                            onLoadTenants={onLoadTenants}
                        />
                    ),
                ]}
            profile={getProfile()}
            notifications={null}
            onLogInClicked={login}
            onLogOutClicked={logout}
            isLoggedIn={isLoggedIn}
            accessToken={auth.getAccessToken()}
        />
    );
}

export default connect(
    (state: RootState) => ({
        availableTenants: state.tenantInfo.availableTenants,
        tenantsLoaded: state.tenantInfo.tenantsLoaded,
    }),
    {
        onSaveCurrentTenant: slice.actions.onSaveTenant,
        onLoadTenants: slice.actions.onLoadTenants,
        connectedPush: push,
    },
)(AppHeader);
