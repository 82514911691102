import { all } from 'redux-saga/effects';

import repository from '../screens/repository/saga';
import fontProperties from '../screens/catalog/addFontPopup/saga';
import fontList from '../fonts/saga';

export default function* sagaRoot() {
    return yield all([
        repository(),
        fontProperties(),
        fontList(),
    ]);
}
