import React from 'react';
import { Button, Icon, Tooltip } from '@cimpress/react-components';

export interface Action {
    title: string;
    icon: string;
    onClick(): void;
}

interface Props {
    actions: Action[];
}

export function Actions({ actions }: Props) {
    return (
        <div className='font-actions'>
            {actions.map(action => (
                <Tooltip direction='top' contents={action.title} key={action.title}>
                    <Button key={action.title} onClick={action.onClick}>
                        <Icon name={action.icon} size='lg' />
                    </Button>
                </Tooltip>
            ))}
        </div>
    );
}
