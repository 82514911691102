import React, { useState } from 'react';

import { FileUploadButton } from './fileUploadButton/FileUploadButton';

import './fileUpload.scss';
import { FileDropZone } from './fileDropZone/FileDropZone';

interface Props {
    showButton?: boolean;
    accept?: string[];
    helpText?: string;
    bsStyle?: string;
    onUpload?: (files: File | undefined) => void;
    onFileInvalid?(): void;
}

function formatFileSize(size: number) {
    if (size >= 1024) {
        const sizeKB = size / 1024;
        if (sizeKB >= 1024) {
            const sizeMB = sizeKB / 1024;
            return `${sizeMB.toFixed(2)} MB`;
        }
        return `${sizeKB.toFixed(2)} KB`;
    }
    return `${size} B`;
}

export function FileUpload({ showButton, accept, helpText, bsStyle, onUpload, onFileInvalid }: Props) {
    const [selectedFile, setSelectedFile] = useState();

    const onFileUpload = (files: File[]) => {
        setSelectedFile(files[0]);
        onUpload && onUpload(files[0]);
    };

    const removeFile = () => {
        setSelectedFile(undefined);
        onUpload && onUpload(undefined);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (event: any) => onFileUpload(event.target.files);

    return (
        <div className={`file-upload ${showButton ? 'show-upload-button' : ''}`}>
            {
                selectedFile
                    ? (
                        <div className='file-upload__selected-file'>
                            <button className='close' onClick={removeFile} type='button'>x</button>
                            <div className='file-upload__file-name'>{ selectedFile.name }</div>
                            <div className='file-upload__file-size'>{ formatFileSize(selectedFile.size) }</div>
                            <div className={`has-${bsStyle}`}>{ helpText && <small className='help-block'>{helpText}</small> }</div>
                        </div>
                    )
                    : (
                        <FileDropZone
                            onFileUpload={onFileUpload}
                            accept={accept}
                            helpText={helpText}
                            bsStyle={bsStyle}
                            onFileInvalid={onFileInvalid}
                        >
                            {
                                showButton
                                    ? (
                                        <>
                                            <div>Drag and Drop file here</div>
                                            <FileUploadButton onFileUpload={onFileUpload} accept={accept}>Upload</FileUploadButton>
                                        </>
                                    )
                                    : (
                                        <>
                                            <div>Drag and Drop file here or click to select</div>
                                            <input className='hidden-file-input' type='file' onChange={onChange} accept={accept && accept.join(',')} />
                                        </>
                                    )
                            }
                        </FileDropZone>
                    )
            }
        </div>
    );
}
