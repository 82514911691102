import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import _ from 'lodash';

import { PayloadAction } from 'redux-starter-kit';
import slice from './slice';
import { getAllFontsDetails, Font, publishFont, deleteFont, fetchAllRepositories, FontRepo } from '../clients/fontClient';
import { getCurrentRepoId, getCurrentRepo } from '../screens/repository/slice';
import { getCurrentTenant } from '../components/header/slice';
import { Tenant } from '../components/tenant/tenantClient';

export interface GroupedFont {
    familyName: string;
    fonts: Font[];
}
const sortedFonts = (fonts: GroupedFont[]) => fonts.sort((a, b) => a.familyName.localeCompare(b.familyName));

export const groupFonts = (queryResponse: Font[]) => {
    const groupedFonts = _.groupBy(queryResponse, 'familyName');
    const groupByFamilyName = Object.keys(groupedFonts).map(fontName => ({
        familyName: fontName,
        fonts: groupedFonts[fontName],
    }));
    return sortedFonts(groupByFamilyName);
};

const getUniqueProperties = (firstFont: Font, secondFont: Font) => firstFont.familyName === secondFont.familyName && firstFont.style === secondFont.style && firstFont.tech === secondFont.tech;

const getUniqueFonts = (masterFonts: Font[], localFonts: Font[]) => _.unionWith(masterFonts, localFonts, getUniqueProperties);

export function* onQueryFonts() {
    const repoId = (yield select(getCurrentRepoId)) as string;
    const repo = (yield select(getCurrentRepo)) as FontRepo;
    const { tenantId, tenantType } = (yield select(getCurrentTenant)) as Tenant;
    try {
        const tenantsRepoList = (yield call(fetchAllRepositories, { tenantId, tenantType })) as FontRepo[];
        let repoIds = [repoId];
        if (repo.inheritedRepositories) {
            repoIds = repoIds.concat(repo.inheritedRepositories);
        }
        const allFonts = (yield all(repoIds.map(rId => call(getAllFontsDetails, rId, !tenantsRepoList.some(tenantRepo => tenantRepo.id === rId))))) as Font[][];
        const [localFonts, masterFonts] = allFonts;
        const uniqueFonts = getUniqueFonts(masterFonts, localFonts);
        yield put(slice.actions.fontsLoadSuccess(groupFonts(uniqueFonts)));
    } catch {
        yield put(slice.actions.fontsLoadFailed());
    }
}

export function* onPublishFont(action: PayloadAction<string>) {
    const fontId = action.payload;
    const repoId = (yield select(getCurrentRepoId)) as string;
    try {
        yield call(publishFont, repoId, fontId);
        yield put(slice.actions.fontPublishSuccess());
    } catch {
        yield put(slice.actions.fontPublishFailed());
    }
}

export function* onDeleteFont(action: PayloadAction<string>) {
    const fontId = action.payload;
    const repoId = (yield select(getCurrentRepoId)) as string;
    try {
        yield call(deleteFont, repoId, fontId);
        yield put(slice.actions.onQueryFonts());
        yield put(slice.actions.fontDeleteSuccess());
    } catch {
        yield put(slice.actions.fontDeleteFailed());
    }
}

export default function* fontListSaga() {
    return yield all([
        yield takeEvery(slice.actions.onQueryFonts, onQueryFonts),
        yield takeEvery(slice.actions.onPublishFont, onPublishFont),
        yield takeEvery(slice.actions.onDeleteFont, onDeleteFont),
    ]);
}
